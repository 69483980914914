import axios from "axios";

/**
 * for sign up
 * @param {Object} {BaseUrl,userName, password, mobile,eboCode}
 * @return {Object}
 */
const signUpApi = async ({ BaseUrl, userName, password, mobile, eboCode }) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/admin/user/signup`, {
      userName,
      password,
      mobile,
      eboCode,
    });
    if (res.data.isSuccess) {
      return { isSuccess: true, data: res.data.data, isAlreadyExist: false };
    } else {
      return {
        isSuccess: false,
        data: null,
        isAlreadyExist: res.data.isAlreadyExist,
      };
    }
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

/**
 * for sign in
 * @param {Object} {BaseUrl,password, mobile}
 * @return {Object}
 */
const signInApi = async ({ BaseUrl, password, mobile }) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/admin/user/signin`, {
      password,
      mobile,
    });
    if (res.data.isSuccess) {
      return { isSuccess: true, data: res.data.data };
    } else {
      return {
        isSuccess: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

// --------> for getting the users information on the basis of query
const get_user_by_query_obj = async ({
  BaseUrl,
  token,
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/get_user_by_query_obj`,
      { queryObject, skip, limit, sortingObj },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};
// -------->creating an access token for the admin to the main website with another's user information
const create_user_access_token = async ({ BaseUrl, token, userId }) => {
  try {
    const res = await axios.get(
      `${BaseUrl}/api/admin/user/create_user_access_token/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};
const handle_creating_new_user = async ({ BaseUrl, token, formData }) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/user/handleCreatingNewUser`,
      { ...formData },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};

//-----------getting USer Cart ---------------
const get_cart_by_query_obj = async ({
  BaseUrl,
  token,
  queryObject,
  skip,
  limit,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/cart/get_cart_by_query_obj`,
      { queryObject, skip, limit },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};

  export const DeleteUserAndProperties = async(BaseUrl,token,id) => {
    console.log(token)
    try{
      const res = await axios.delete(
        `${BaseUrl}/api/admin/user/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      console.log(res)
    }
    catch(e){
      console.log(e)
    }
  }

export {
  signUpApi,
  signInApi,
  get_user_by_query_obj,
  create_user_access_token,
  handle_creating_new_user,
  get_cart_by_query_obj,
};
