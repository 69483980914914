import { createContext, useContext, useEffect, useState } from "react";
import { signUpApi, signInApi } from "../api/userApi";
import { toast } from "react-toastify";
import StoreContext from "./StoreContext";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [adminState, setAdminState] = useState({
    isLoading: false,
    admin: null,
  });

  // ------------- context --------------
  const { BaseUrl } = useContext(StoreContext);

  // ----------- LOCAL STORAGE ----------
  const LC_TOKEN = localStorage.getItem("ACCESS_TOKEN");

  // ------------------------ useEffect  ------------------------------

  useEffect(() => {
    setAdminState({
      ...adminState,
      admin: LC_TOKEN,
    });
    // eslint-disable-next-line
  }, []);

  // ----------------------- functions -------------------------

  const logout = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    setAdminState({
      ...adminState,
      admin: null,
    });
  };

  /**
   * Signup context function
   */
  const signUp = async (formData) => {
    try {
      setAdminState({
        ...adminState,
        isLoading: true,
      });
      const { mobile, userName, password, eboCode } = formData;

      const res = await signUpApi({
        BaseUrl: BaseUrl,
        userName: userName,
        password: password,
        mobile: mobile,
        eboCode: eboCode,
      });

      if (res.isSuccess) {
        toast.warning("Loged in successfully");
        localStorage.setItem("ACCESS_TOKEN", res.data);
        setAdminState({
          admin: res.data,
          isLoading: false,
        });
      } else if (res.isAlreadyExist) {
        toast.warning("user already exists, try to login");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      } else {
        toast.warning("Not got the right response, try again");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      }
    } catch (error) {
      toast.warning("Got an error, checkout the console for more information");
      setAdminState({
        ...adminState,
        isLoading: false,
      });

      console.log(error);
    }
  };
  /**
   * Signin context function
   */
  const signIn = async (formData) => {
    try {
      setAdminState({
        ...adminState,
        isLoading: true,
      });
      const { mobile, password } = formData;

      const res = await signInApi({
        BaseUrl: BaseUrl,
        password: password,
        mobile: mobile,
      });

      if (res.isSuccess) {
        toast.warning("Loged in successfully");
        localStorage.setItem("ACCESS_TOKEN", res.data);
        setAdminState({
          admin: res.data,
          isLoading: false,
        });
      } else {
        toast.warning("Not got the right response, try again");
        setAdminState({
          ...adminState,
          isLoading: false,
        });
      }
    } catch (error) {
      toast.warning("Got an error, checkout the console for more information");
      setAdminState({
        ...adminState,
        isLoading: false,
      });

      console.log(error);
    }
  };

  return (
    <AdminContext.Provider
      value={{ adminState, setAdminState, signUp, signIn, LC_TOKEN, logout }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
