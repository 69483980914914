import { createContext, useState } from "react";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [orders, setOrders] = useState({
    isLoading: false,
    orders: [],
    setOrders: 0,
  });


  // -------------------- constants --------------------

  const BaseUrl = process.env.REACT_APP_API_URL;
  // const BaseUrl = "http://localhost:5001";

  // ------------------------ useEffect  ------------------------------

  // ----------------------- functions -------------------------

  return (
    <StoreContext.Provider value={{ BaseUrl, orders, setOrders }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
