import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthMiddleware from "../middlewareComponents/AuthMiddleware";


//--->  pages
const StatisticsPage = lazy(() => import("../pages/StatisticsPage"))
const CustomInvoicePage = lazy(() => {
  return import("../pages/CustomInvoicePage");
});
const ProductPage = lazy(() => {
  return import("../pages/ProductPage");
});
const Order = lazy(() => {
  return import("../pages/Order");
});

const SingleOrderDetailPage = lazy(() => import("../pages/SingleOrderDetailPage"));
const LoginPage = lazy(() => {
  return import("../pages/LoginPage");
});
const Home = lazy(() => {
  return import("../pages/Home");
});
const HomePage = lazy(() => {
  return import("../pages/HomePage");
});
const BlogPage = lazy(() => {
  return import("../pages/BlogPage");
});
const AddonPage = lazy(() => {
  return import("../pages/AddonPage");
});
const CoupanPage = lazy(() => {
  return import("../pages/CoupansPage");
});
const UserPage = lazy(() => {
  return import("../pages/UserPage");
});
const SitemapPage = lazy(() => {
  return import("../pages/SitemapPage");
});
const Checkout = lazy(() => {
  return import("../pages/Checkout");
});
const FreebiePage = lazy(() => {
  return import("../pages/FreebiePage");
});
const MaualOrderPlacePage = lazy(() => {
  return import("../pages/MaualOrderPlacePage");
});
const UpComingCelebrationPage = lazy(() => {
  return import("../pages/UpComingCelebrationPage");
});

const SingleProductDetailPage = lazy(() => import("../pages/SingleProductDetailPage"));
const SingleAddonDetailPage = lazy(() => import("../pages/SingleAddonDetailPage"));
const TimeSlotPage = lazy(() => import("../pages/TimeSlotPage"));

const EboRoutes = () => {
  return (
    <Routes>
      <Route path="/invoice-generator" element={<AuthMiddleware />}>
        <Route
          path="/invoice-generator"
          element={
            <Suspense fallback={"loading..."}>
              <CustomInvoicePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<AuthMiddleware />}>
        <Route
          path="/"
          element={
            <Suspense fallback={"loading..."}>
              <Home />
            </Suspense>
          }
        />
      </Route>

      <Route path="/order" element={<AuthMiddleware />}>
        <Route
          path="/order"
          element={
            <Suspense fallback={"loading..."}>
              <Order />
            </Suspense>
          }
        />
      </Route>

      <Route path="/order-detail" element={<AuthMiddleware />}>
        <Route
          path="/order-detail"
          element={
            <Suspense fallback={"loading..."}>
              <SingleOrderDetailPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/order/manual-order-place" element={<AuthMiddleware />}>
        <Route
          path="/order/manual-order-place"
          element={
            <Suspense fallback={"loading..."}>
              <MaualOrderPlacePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/homepage" element={<AuthMiddleware />}>
        <Route
          path="/homepage"
          element={
            <Suspense fallback={"loading..."}>
              <HomePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/blog" element={<AuthMiddleware />}>
        <Route
          path="/blog"
          element={
            <Suspense fallback={"loading..."}>
              <BlogPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/signin"
        element={
          <Suspense fallback={"loading..."}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route path="/Admin/stats" element={<AuthMiddleware />}>
        <Route path="/Admin/stats" element={
          <Suspense fallback={"loading..."}>
            <StatisticsPage />
          </Suspense>
        } />
      </Route>

      <Route path="/Admin/product" element={<AuthMiddleware />}>
        <Route
          path="/Admin/product"
          element={
            <Suspense fallback={"loading..."}>
              <ProductPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/product-detail" element={<AuthMiddleware />}>
        <Route
          path="/product-detail"
          element={
            <Suspense fallback={"loading..."}>
              <SingleProductDetailPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/timeslot-detail" element={<AuthMiddleware />}>
        <Route
          path="/timeslot-detail"
          element={
            <Suspense fallback={"loading..."}>
              <TimeSlotPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/Admin/user" element={<AuthMiddleware />}>
        <Route
          path="/Admin/user"
          element={
            <Suspense fallback={"loading..."}>
              <UserPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/Celebration" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Celebration"
          element={
            <Suspense fallback={"loading..."}>
              <UpComingCelebrationPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/AddOn" element={<AuthMiddleware />}>
        <Route
          path="/Admin/AddOn"
          element={
            <Suspense fallback={"loading..."}>
              <AddonPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/addOn-detail" element={<AuthMiddleware />}>
        <Route
          path="/addOn-detail"
          element={
            <Suspense fallback={"loading..."}>
              <SingleAddonDetailPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/FreeBie" element={<AuthMiddleware />}>
        <Route
          path="/Admin/FreeBie"
          element={
            <Suspense fallback={"loading..."}>
              <FreebiePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/Coupans" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Coupans"
          element={
            <Suspense fallback={"loading..."}>
              <CoupanPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/Admin/SitemapGenerator" element={<AuthMiddleware />}>
        <Route
          path="/Admin/SitemapGenerator"
          element={
            <Suspense fallback={"loading..."}>
              <SitemapPage />
            </Suspense>
          }
        />
      </Route>

      <Route path="/Admin/Checkout" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Checkout"
          element={
            <Suspense fallback={"loading..."}>
              <Checkout />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default EboRoutes;
