import React, { useContext, lazy, Suspense } from "react";

// -------------- ** CONTEXTS ** ----------------
import ModalContext from "../context/ModalContext";

// -------------------- ** COMPONENTS ** ----------------

const WarningModal = lazy(() =>
  import("../components/modals/warningModal/WarningModal")
);

export default function Modals() {
  // ------------ ** CONTEXTS ** -------------------------
  const { warningModal } = useContext(ModalContext);

  return (
    <div className="pop-up-components">
      {warningModal.isRender && (
        <Suspense fallback={<h1>loading...</h1>}>
          <WarningModal />
        </Suspense>
      )}
    </div>
  );
}
