/**
 * This context will store the all modal opening and closing states
 */
import { createContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  // ---------------------- ** STATES ** --------------------
  const [warningModal, setWarningModal] = useState({
    text: "Do you realy want to remove ?",
    isRender: false,
    funToBeExecuted: null,
    funToBeExecutedOnNo: null,
  });

  return (
    <ModalContext.Provider value={{ warningModal, setWarningModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
