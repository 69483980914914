import React, { useContext } from "react";
import AdminContext from "../../context/AdminContext";
export default function ErrorPage() {
  const { logout } = useContext(AdminContext);

  return (
    <div className="flex tracking-[.6px] leading-[22px] text-[#1717171] min-h-[100vh] w-full flex-col items-center justify-center ">
      <div className="flex  flex-col items-center justify-center ">
        <div className="flex flex-col w-full ">
          <p className="text-[2.2rem] mb-[.5rem] text-[#2136d4] font-[900]">
            Oops!
          </p>
          <p className="text-[1.2rem] font-[600] ">Something went wrong</p>
          <p className="text-[.9rem] ">Don't worry, our team is here to help</p>
        </div>
        <div className="flex mt-[1rem] w-full text-[.8rem] ">
          <div className="flex flex-col gap-2 ">
            <h2 className="text-[1rem] font-[600] ">Quick Suggestions</h2>
            <p>
              <button
                className=" bg-[#2136d4] text-[white] text-[.825rem] px-2 py-[1px] rounded-lg  "
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>{" "}
              the page
            </p>
            <p>
              <button
                className=" bg-[#2136d4] text-[white] text-[.825rem] px-2 py-[1px] rounded-lg  "
                onClick={() => {
                  logout();
                }}
              >
                Re-login
              </button>{" "}
              to your account{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
