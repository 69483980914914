import React, { Fragment } from "react";
import "./index.css";
import ErrorBoundary from "./components/ErrorComponents/ErrorBoundary";
import ErrorPage from "./components/ErrorComponents/ErrorPage";
// ----------- toast -----------
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EboRouter from "./routes/EboRouter";
// ------------ context provider --------------------
import { AdminProvider } from "./context/AdminContext";
import { StoreProvider } from "./context/StoreContext";
import { ModalProvider } from "./context/ModalContext";

export default function App() {
  return (
    <Fragment>
      <StoreProvider>
        <ModalProvider>
          <AdminProvider>
            <ErrorBoundary fallback={<ErrorPage />}>
              <EboRouter />
            </ErrorBoundary>
          </AdminProvider>
        </ModalProvider>
      </StoreProvider>
      <ToastContainer limit={1} />
    </Fragment>
  );
}
