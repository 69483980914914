import React, { memo } from "react";

import EboRoutes from "./EboRoutes";

import { BrowserRouter as Router } from "react-router-dom";
import Modals from "./Modals";

const EboRouter = () => {
  return (
    <Router>
      <Modals />
      <EboRoutes />
    </Router>
  );
};

export default memo(EboRouter);
